<template>
  <section
    v-if="pageStore.ActionsOnMain"
    id="v-banners-main-page__Minas-Morgul"
  >
    <div
      v-if="pageStore.Actions.state === 'success'"
      class="v-container"
    >
      <div class="v-row">
        <div class="v-col-12">
          <arora-swiper-slider
            class-name="v-banner-main-page-item__Minas-Morgul"
            :autoplay-delay="appConfig.VueSettingsPreRun.ActionsBannersDelaySec * 1000"
            :items="pageStore.ActionsOnMain"
            :max-items="isDesktopVersion ? 5 : 2.1"
            :min-items="isDesktopVersion ? 1 : 2.1"
            :pagination-type="isDesktopVersion ? 'dynamic-bullets' : 'none'"
            :space-between="20"
          >
            <template #item="item: Action">
              <arora-nuxt-link
                class="v-banner-info"
                :href="item.UseLinkOnBanner ? item.LinkOnBanner : item.Link"
                :label="item.Title"
              >
                <arora-nuxt-image
                  disable-lazy
                  :alt="item.Title"
                  :image="item.ImageSmallEventInfo"
                />
                <div
                  v-if="!item.NotShowTextOnBanner"
                  class="v-description-action-block"
                >
                  <div
                    class="v-title"
                    v-html="sanitize(item.Title)"
                  />
                  <div
                    class="v-teaser"
                    v-html="sanitize(item.Teaser)"
                  />
                </div>
              </arora-nuxt-link>
            </template>
          </arora-swiper-slider>
        </div>
      </div>
    </div>
    <common-skeleton
      v-else
      :height="175"
      :loaders-in-row="5"
      :space-between="20"
      :width="175"
    />
  </section>
</template>

<script setup lang="ts">
import type { Action } from '~types/pageStore'

import { useWindowSize } from '@arora/common'
const { sanitize } = useI18nSanitized()

const appConfig = useAppConfig()
const { isDesktopVersion } = useWindowSize()

const pageStore = usePageStore()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

#v-banners-main-page__Minas-Morgul {
  margin-bottom: 60px;
  @include mixins.sm {
    margin-bottom: 20px;
  }
  .summary-wrapper {
    padding: 0;
  }
  .v-banner-main-page-item__Minas-Morgul {
    .swiper-pagination-bullets {
      bottom: -12px;
      .swiper-pagination-bullet-active {
        background: variables.$SecondaryBackgroundColor;
      }
    }
  }
  .v-banner-info {
    filter: drop-shadow(0px 2px 4px rgba(30, 0, 0, 0.08));
    border-radius: variables.$BorderRadius;
    position: relative;
    border: 2px solid variables.$SecondaryBackgroundColor;
    padding: 6px;
    display: flex;
    img {
      border-radius: variables.$BorderRadius;
    }
    .v-description-action-block {
      position: absolute;
      bottom: 6px;
      left: 6px;
      right: 6px;
      color: #000015;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.6);
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .v-title {
        position: relative;
        z-index: 2;
        padding: 12px 12px 0 12px;
        font-size: 1rem;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .v-teaser {
        position: relative;
        z-index: 2;
        padding: 0 12px 12px;
        font-size: 0.8rem;
        opacity: 0.8;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
